<template>
  <div id="main-app">
    <ZHeader :menu="menu" />
    <b-overlay
      :show="$appLoading"
      variant="transparent"
      opacity="0.85"
      blur="5px"
      rounded="sm"
    >
      <router-view />
    </b-overlay>
    <b-modal
      hide-footer
      hide-header
      id="prompt-modal"
      centered
      title="App install"
    >
      <p class="my-4">Cài đặt app để trải nghiệm tốt hơn</p>
      <b-button variant="danger" @click="promptInstall">Cài đặt</b-button>
    </b-modal>
    <ZFooter :menu="menu" />
  </div>
</template>

<script>
import ZHeader from "@/components/ZHeader";
import ZFooter from "@/components/ZFooter";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      menu: [],
      deferredPrompt: null
    };
  },
  components: {
    ZHeader,
    ZFooter
  },
  methods: {
    fetchParseTypes() {
      if (this.parseTypes.length > 0) return;
      this.$store.dispatch("getParseTypes");
    },
    fetchGameMapping() {
      if (Object.keys(this.game).length > 0) return;
      this.$store.dispatch("getGameMapping");
    },
    fetchPlayerList() {
      this.$store.dispatch("getPlayers");
    },
    fetchUserSetting() {
      this.$store.dispatch("getUserSetting");
    },
    async fetchMenu() {
      let res = await this.$http.get("menu");
      this.menu = res.data.data;
    },
    initEvent() {
      if (window.innerWidth <= 900) {
        let vm = this;
        window.addEventListener("beforeinstallprompt", e => {
          // Prevent Chrome 67 and earlier from automatically showing the prompt
          e.preventDefault();
          // Stash the event so it can be triggered later.
          vm.deferredPrompt = e;

          vm.$bvModal.show("prompt-modal");
          // vm.$refs['prompt-modal'].show();
        });
      }
    },
    promptInstall() {
      let vm = this;
      if (!vm.deferredPrompt) return;

      vm.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      vm.deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        vm.deferredPrompt = null;
      });
    }
  },
  computed: {
    ...mapGetters(["currentUser", "parseTypes", "game"])
  },
  created() {
    this.initEvent();
    this.fetchParseTypes();
    this.fetchGameMapping();
    this.fetchPlayerList();
    if (this.currentUser.role[0] == "user") {
      this.fetchUserSetting();
    }
    this.fetchMenu();
  }
};
</script>

<style>
div#main-app {
  display: flex;
  flex-direction: column;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 60px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#user-info-modal footer {
  display: none;
}
</style>
