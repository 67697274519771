<template>
  <b-navbar
    toggleable="lg"
    :type="isDarkMode == 1 ? 'dark' : 'light'"
    :variant="isDarkMode == 1 ? 'dark' : 'light'"
    class="d-none d-sm-flex"
  >
    <b-navbar-brand href="#">
      <!-- <img src="@/assets/logo.png" class="max-h-35px" height="35" /> -->
      <img src="/img/logo.png" class="max-h-35px" height="35" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item
          :to="{ name: item.link }"
          :active="isMenuActive(item)"
          v-for="(item, indexItem) in linkMenu"
          :key="indexItem"
          >{{ item.title }}</b-nav-item
        >
        <b-nav-item
          :to="{ name: 'guide' }"
          :class="
            'navi-link ' + (isMenuActive({ link: 'guide' }) ? 'active' : '')
          "
        >
          Hướng dẫn
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template #button-content>
            <em
              >{{ currentUser.name }}
              <small>({{ currentUser.role[0] }})</small></em
            >
          </template>
          <b-dropdown-item>Ngày hết hạn: {{ expire_date }}</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-group id="dropdown-group-1" header="Tài khoản">
            <b-dropdown-item
              :to="{ name: 'setting' }"
              v-if="currentUser.role[0] == 'user'"
              >Cài đặt</b-dropdown-item
            >
            <b-dropdown-item :to="{ name: 'changePass' }"
              >Đổi mật khẩu</b-dropdown-item
            >
            <b-dropdown-item
              :to="{ name: 'subUsers' }"
              v-if="currentUser.role[0] == 'user'"
              >Tài khoản phụ</b-dropdown-item
            >
          </b-dropdown-group>
          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-group id="dropdown-group-2" header="Hỗ trợ">
            <b-dropdown-text
              text-class="d-flex justify-content-between align-items-center"
              >Phiên bản: <span> {{ version }}</span></b-dropdown-text
            >
            <b-dropdown-form
              form-class="ml-auto d-flex justify-content-between align-items-center"
            >
              Giao diện tối
              <b-form-checkbox
                id="switch_1"
                v-model="isDarkMode"
                name="check-button"
                switch
                @change="saveSetting"
              >
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown-group>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="logout">Đăng xuất</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: ["menu"],
  components: {},
  data() {
    return {
      linkMenu: [],
      linkConfig: [],
      version: process.env.VUE_APP_VERSION,
      isDarkMode: null
    };
  },
  computed: {
    ...mapGetters(["currentUser", "userSetting"]),
    expire_date: function() {
      if (typeof this.currentUser.expired_in == "string") {
        return this.currentUser.expired_in;
      }

      let expries = moment()
        .add(this.currentUser.expired_in - 1, "days")
        .format("DD-MM-YYYY");
      return expries;
    }
  },
  watch: {
    userSetting: function(nVal) {
      this.isDarkMode = nVal.theme == 1 ? true : false;
    },
    isDarkMode: function(newVal) {
      let htmlElement = document.documentElement;
      if (newVal) {
        htmlElement.setAttribute("theme", "dark");
      } else {
        htmlElement.setAttribute("theme", "light");
      }
    },
    menu: function(m) {
      this.linkMenu = m.filter(function(el) {
        return el.title != "setting-group";
      });
      this.linkConfig = m.filter(function(el) {
        return el.title == "setting-group";
      })[0].children;

      this.linkMenu.sort(function(a, b) {
        return a.priority - b.priority;
      });
    }
  },
  methods: {
    logout() {
      let vm = this;
      vm.$http.post("//" + process.env.VUE_APP_API + "/logout").then(res => {
        if (res.data.code == 0) {
          vm.$store.dispatch("logout");
          window.location.reload();
        }
      });
    },
    isMenuActive(item) {
      let routerName = this.$route.name;
      const routeParent = this.$route.meta
        ? this.$route.meta.parent
        : undefined;

      return (
        item.link && (routerName === item.link || routeParent === item.link)
      );
    },
    saveSetting() {
      let theme = this.isDarkMode ? 1 : 0;
      this.$http.post("/user/setting", {
        theme: theme
      });
    }
  },
  mounted() {}
};
</script>
