<template>
  <div class="footer footer-menu d-sm-none">
    <b-nav pills>
      <b-nav-item
        class="nav-item-icon-text"
        :to="{ name: item.link }"
        :active="isMenuActive(item)"
        v-for="(item, indexItem) in linkMenu"
        :key="indexItem"
      >
        <div class="d-flex flex-column align-items-center menu-item pt-1 item">
          <b-icon :icon="item.icon"></b-icon>
          <span class="title">{{ item.title }}</span>
        </div>
      </b-nav-item>

      <b-nav-item
        v-b-modal.user-info-modal
        class="nav-item-icon-text"
        :active="isMenuActive({ link: 'userGroup' })"
      >
        <div class="d-flex flex-column align-items-center menu-item pt-1 item">
          <b-icon icon="gear-fill"></b-icon>
          <span class="title">Cài đặt</span>
        </div>
      </b-nav-item>
    </b-nav>
    <div class="user-settingxx">
      <b-modal ref="user-info-modal" id="user-info-modal" centered>
        <template #modal-header>
          <span
            >{{ currentUser.name.toUpperCase() }}
            <small>({{ currentUser.role[0] }})</small></span
          >
          <small>Ngày hết hạn: {{ expire_date }}</small>
        </template>

        <div class="text-sub ml-2">Tài khoản</div>
        <b-list-group>
          <b-list-group-item
            :to="{ name: 'setting' }"
            @click="hideModal"
            v-if="currentUser.role[0] == 'user'"
            >Cài đặt</b-list-group-item
          >
          <b-list-group-item :to="{ name: 'changePass' }" @click="hideModal"
            >Đổi mật khẩu</b-list-group-item
          >
          <b-list-group-item
            :to="{ name: 'subUsers' }"
            @click="hideModal"
            v-if="currentUser.role[0] == 'user'"
            >Tài khoản phụ</b-list-group-item
          >
        </b-list-group>
        <hr role="separator" aria-orientation="horizontal" />

        <div class="text-sub ml-2">Hỗ trợ</div>
        <b-list-group>
          <b-list-group-item :to="{ name: 'guide' }" @click="hideModal"
            >Hướng dẫn</b-list-group-item
          >
          <b-list-group-item href="https://t.me/samsam052" target="_blank"
            >Chat telegram kỹ thuật</b-list-group-item
          >
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            Giao diện tối
            <b-form-checkbox
              id="switch_1"
              v-model="isDarkMode"
              name="check-button"
              class="ml-auto"
              switch
              @change="saveSetting"
            >
            </b-form-checkbox>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            >Phiên bản: <span>{{ version }}</span></b-list-group-item
          >
        </b-list-group>
        <hr role="separator" aria-orientation="horizontal" />

        <div class="user-setting-content ml-3">
          <a @click="logout">
            <b-icon icon="box-arrow-left" aria-hidden="true"></b-icon> Đăng xuất
          </a>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: ["menu"],
  components: {},
  data() {
    return {
      linkMenu: [],
      linkConfig: [],
      version: process.env.VUE_APP_VERSION,
      isDarkMode: null
    };
  },
  computed: {
    ...mapGetters(["currentUser", "userSetting"]),
    expire_date: function() {
      if (typeof this.currentUser.expired_in == "string") {
        return this.currentUser.expired_in;
      }

      let expries = moment()
        .add(this.currentUser.expired_in - 1, "days")
        .format("DD-MM-YYYY");
      return expries;
    }
  },
  watch: {
    userSetting: function(nVal) {
      this.isDarkMode = nVal.theme == 1 ? true : false;
    },
    menu: function(m) {
      this.linkMenu = m.filter(function(el) {
        return el.title != "setting-group";
      });
      this.linkConfig = m.filter(function(el) {
        return el.title == "setting-group";
      })[0].children;
    },
    isDarkMode: function(newVal) {
      let htmlElement = document.documentElement;
      if (newVal) {
        htmlElement.setAttribute("theme", "dark");
      } else {
        htmlElement.setAttribute("theme", "light");
      }
    }
  },
  methods: {
    logout() {
      let vm = this;
      vm.$http.post("//" + process.env.VUE_APP_API + "/logout").then(res => {
        if (res.data.code == 0) {
          vm.$store.dispatch("logout");
          window.location.reload();
        }
      });
    },
    hideModal() {
      this.$refs["user-info-modal"].hide();
    },
    isMenuActive(item) {
      let routerName = this.$route.name;
      const routeParent = this.$route.meta
        ? this.$route.meta.parent
        : undefined;

      return (
        item.link && (routerName === item.link || routeParent === item.link)
      );
    },
    saveSetting() {
      let theme = this.isDarkMode ? 1 : 0;
      this.$http.post("/user/setting", {
        theme: theme
      });
    }
  },
  mounted() {
    let bodyElement = document.body;
    bodyElement.classList.add("app-background");

    // let htmlElement = document.documentElement;
    // let theme = localStorage.getItem("theme");
    // if (theme === "dark") {
    //   htmlElement.setAttribute("theme", "dark");
    //   this.isDarkMode = true;
    // } else {
    //   htmlElement.setAttribute("theme", "light");
    //   this.isDarkMode = false;
    // }
  }
};
</script>

<style>
.footer {
  position: fixed;
  bottom: -1px;
  left: 0;
  right: 0;
  box-shadow: 0 -5px 10px 0 rgb(0 0 0 / 6%);
  min-height: calc(50px + (env(safe-area-inset-bottom)) * 1.1);
  transition: all 350ms ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}
.footer .add-bet img.icon {
  content: url("/img/icon-taisan-inactive-light.svg");
}
.footer .router-link-active img.icon {
  content: url("/img/icon-taisan-active-light.svg");
}
.footer .add-bet {
  width: 100%;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -12%);
  position: relative;
}
.footer .add-bet .col-center-main {
  width: 70px;
  left: 50%;
  z-index: 1;
}
.footer .add-bet .col-center-sub {
  width: calc(50% - 30px);
  /* background: #fff; */
  position: absolute;
  height: 50px;
  z-index: 2;
}
.footer .add-bet .col-center-sub.left {
  left: -2px;
  top: 3px;
}
.footer .add-bet .col-center-sub.right {
  right: -2px;
  top: 3px;
}
.footer .add-bet .icon {
  height: 60px;
  width: 54px;
  top: 50%;
  left: 38%;
  transform: translate(-50%, -30%);
  position: relative;
}
.footer .add-bet .bg-icon {
  top: -32px !important;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}
.footer .nav-pills .nav-link {
  border-radius: 0px;
}
.footer .nav-link {
  padding: 0;

  height: 50px;
}
.footer .dropdown-toggle::after {
  display: none;
}
.footer .title {
  font-size: 0.7rem;
  font-weight: 500;
  opacity: 0.7;
}
.footer .nav-item {
  flex: 1;
}
.footer .nav-item-icon-text {
  line-height: 1.8;
}
.footer .b-dropdown-text {
  display: block;
}
.footer .expired {
  line-height: 0px;
  width: 240px;
  margin-top: -15px;
}
.menu-item {
  /* icon inherit from parent */
  color: #1f1f1f;
  font-size: 1.3rem;
  /* icon inherit from parent */
}
.active .menu-item {
  color: #da4453 !important;
}
.nav-pills .nav-link.active {
  background: none;
}
.nav-item {
  align-self: center;
}
.nav-pills .show > .nav-link {
  background: none;
}
#user-info-modal .modal-header {
  background-color: #da4453 !important;
  color: #fff;
  border-bottom: none;
}
.footer-menu {
  padding-bottom: env(safe-area-inset-bottom, 0);
}
.add-bet {
  /* background-color: #e0e0e0; */
}
.col-center-sub.patch {
  width: 100% !important;
  bottom: calc(env(safe-area-inset-bottom, 0) - 13px) !important;
}
</style>
